import { Box } from '@chakra-ui/react'
import React from 'react'
import PlayStoreImage from '../image/PlayStoreImage'

interface Props {
    src: string
}

const AppIcon: React.FC<Props> = ({ src }) => {
    return (
        <Box
            _hover={{
                transform: 'scale(1.05)',
            }}
            transition="all 0.5s"
        >
            <PlayStoreImage
                src={src}
                borderRadius="lg"
                boxShadow="md"
                itemProp="image"
            />
        </Box>
    )
}

export default AppIcon
