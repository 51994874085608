import { Box, Divider, Flex, HStack, VStack } from '@chakra-ui/react'
import { IAppItemFullDetail } from 'google-play-scraper'
import React from 'react'
import AppDiscover from '../components/apps/AppDiscover'
import AppGallery from '../components/apps/AppGallery'
import AppIcon from '../components/apps/AppIcon'
import Author from '../components/author/Author'
import PageContainer from '../components/container/PageContainer'
import Heading from '../components/typography/Heading'
import Text from '../components/typography/Text'
import Layout from '../layout/Layout'
import featured from '../../content/lists/featured.json'
import { HeadFC } from 'gatsby'
import SEO from '../components/SEO'
import { getAuthor, getStoreUrls } from '../utils'
import GoogleStoreButton from '../components/buttons/GoogleStoreButton'
import AppleStoreButton from '../components/buttons/AppleStoreButton'

interface Props {
    pageContext: {
        app: IAppItemFullDetail
    }
}

const AppOverview: React.FC<Props> = (props) => {
    const {
        pageContext: { app },
    } = props

    const {
        icon,
        headerImage,
        title,
        screenshots,
        genre,
        descriptionHTML,
        summary,
    } = app

    const { appleUrl, googleUrl } = getStoreUrls(app)

    return (
        <Layout
            fullWidth={true}
            itemScope
            itemType="https://schema.org/SoftwareApplication"
        >
            <Box position="relative" mb={10}>
                <Flex
                    position="absolute"
                    height="full"
                    bg="primary.700"
                    w="full"
                    bgImage={headerImage}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    boxShadow="inner"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="black"
                    filter="opacity(0.4) blur(10px)"
                />
                <Flex
                    position="absolute"
                    top={0}
                    bottom={0}
                    w="full"
                    justifyContent="center"
                    bg="black"
                    opacity={0.2}
                />
                <Flex
                    direction="column"
                    alignItems="center"
                    top={0}
                    justifyContent="center"
                    py={{ base: 0, lg: 6 }}
                >
                    <AppGallery images={screenshots} />

                    <HStack zIndex={2} spacing={6}>
                        <GoogleStoreButton url={googleUrl} />
                        {Boolean(appleUrl) && (
                            <AppleStoreButton url={appleUrl} />
                        )}
                    </HStack>
                </Flex>
            </Box>
            <PageContainer>
                <VStack mb={0} spacing={6}>
                    <Box width="80px" mb={0}>
                        <AppIcon src={icon} />
                    </Box>
                    <Heading
                        as="h1"
                        fontSize="5xl"
                        textTransform="uppercase"
                        fontWeight="bold"
                        textAlign="center"
                        itemProp="name"
                    >
                        {title}
                    </Heading>
                    <Heading
                        fontSize="2xl"
                        fontWeight="normal"
                        itemProp="applicationCategory"
                    >
                        {genre}
                    </Heading>
                    <Text fontSize="lg" itemProp="abstract">
                        {summary}
                    </Text>
                </VStack>
            </PageContainer>
            <AppGallery images={screenshots} chunkNumber={1} />
            <PageContainer>
                <Heading as="h2" fontSize="3xl" fontWeight="normal" mb={6}>
                    What is it about?
                </Heading>
                <Box
                    dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                    itemProp="text"
                />
                <Divider my={6} />
                <Author seed={app.appId} />
            </PageContainer>
            <AppDiscover app={app} list={featured} />
        </Layout>
    )
}

export default AppOverview

export const Head: HeadFC = (props) => {
    const {
        location,
        pageContext: { app },
    } = props

    const {
        icon,
        headerImage,
        title,
        screenshots,
        genre,
        description,
        descriptionHTML,
        summary,
    } = app

    const author = getAuthor(app.appId)

    return (
        <SEO
            description={description}
            image={icon}
            location={location.pathname}
            summary={summary}
            title={title}
            author={`${author.firstname} ${author.lastname}`}
        />
    )
}
